import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="name-container">
          <p>
            <b>Ethan Narang</b>
          </p>
        </div>
        <div className="linkedin-container">
          <a href="https://linkedin.com/in/ethan-narang">
            <img
              src={process.env.PUBLIC_URL + "/linkedin.png"}
              alt="LinkedIn"
            />
          </a>
        </div>
      </header>
      <main className="App-main">
        <ul>
          <li>
            Software Engineer Intern at{" "}
            <a href="https://www.trypallet.com">Pallet</a>
          </li>
          <li>CS at Cornell</li>
        </ul>
      </main>
    </div>
  );
}

export default App;
